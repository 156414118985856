/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";

/* === Core Styles === */
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
// @import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/loaders/loaders";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";

table td {
  border: none !important;
}
body {
  background-color: #fff;
  color: #000;
}
.content-wrapper {
  padding: 10px 5px !important;
  background: unset !important;
}
.table thead tr {
  background: #2a2e33;
}
.columan_Change {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #1f1f1f;
}
.card {
  background: none;
  box-shadow: rgba(137, 124, 124, 0.16) 0px 10px 36px 0px,
    rgba(48, 48, 48, 0.06) 0px 0px 0px 1px !important;
}
.atropos-rotate .card {
  margin: 2px;
}
.text-muted,
.preview-list .preview-item .preview-item-content p .content-category {
  color: #fff !important;
  font-size: 14px;
  font-weight: 600 !important;
}
.sidebar {
  background: #16191f;
}
button:focus {
  outline: 0;
}
.icon-show {
  display: none;
}
@media screen and (max-width: "769px") {
  .card .card-body {
    padding: 10px 5px !important;
  }
}
@media screen and (max-width: "991px") {
  .sidebar .nav {
    background: #16191f;
  }
  .navbar .navbar-menu-wrapper {
    padding: 0px !important;
  }
  .icon-show {
    display: block;
  }
}

.toggle-button {
  background-color: #ccc;
  border: none;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.toggle-button.active {
  background-color: #00aaff;
}

.toggle-text {
  font-weight: bold;
}

.table {
  margin-bottom: 0;
  color: #6c7293;
  // border: 1px solid;
}
.table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  /* border-radius: 16px 16px 0px 0px; */
  background-color: var(--black, #2a2e33);
  box-shadow: none;
  color: var(--BG, #fff);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 15px;
  border-bottom: 0;
  border-bottom: 1px solid rgb(255 255 255 / 20%) !important;
}
.table td {
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1;
  background: #2a2e33;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
  padding: 12px;
  box-shadow: none;
}
.table tbody tr td {
  border-bottom: 1px solid rgb(255 255 255 / 20%) !important;
}
// .table tbody:last-child tr td{
//   border: 0 !important;
// }
.table td:first-child {
  border-top: 0;
}
.sidebar .sidebar-brand-wrapper {
  background-color: #16191f;
}
.navbar {
  background-color: #16191f;
}
.card-body.text-light {
  background: #2a2e33 !important;
}
.main-panel {
  background: #16191f;
}
.dataTables_wrapper .dataTables_length select {
  background-color: #16191f;
  color: #fff;
}
.dataTables_wrapper .dataTables_filter input {
  background-color: #16191f;
  color: #fff;
}

.card-header {
  color: #e4e4e4;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #e4e4e4 !important;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #e4e4e4;
}
.pagination .page-item .page-link {
  min-width: 40px;
  min-height: 40px;
  border-radius: 5px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e4e4e4;
  background: #2a2e33 !important;
  cursor: pointer;
}
.page-item.disabled,
.page-item:is(:last-child, :first-child) {
  border-radius: 3px;
  background: #2a2e33;
  color: #e4e4e4;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 20px;
}
.pagination .page-item.active .page-link {
  color: #e4e4e4 !important;
  background: #0073bb !important;
  border-radius: 5px;
  border: 1px solid #0073bb;
}
.page-item.disabled,
.page-item:is(:last-child, :first-child) .page-link {
  background: transparent !important;
}
/* ..pagination .page-item .page-link */
.notification_responsive table thead tr th:nth-child(2) {
  min-width: 230px;
}
.pagination .previous .page-link,
.pagination .next .page-link {
  color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  width: 100px !important;
  height: 40px;
  display: flex;
  justify-content: center;
}
.pagination .page-item.previous .page-link.active,
.pagination .page-item.next .page-link.active {
  color: #000;
  background-color: #e4e4e4 !important;
  border-radius: 15px;
}

.pagination .page-link:hover,
.pagination .page-link:focus {
  background: transparent;
  box-shadow: none;
}
.pagination li {
  margin: 0 10px;
}

.card .card-title {
  color: #e4e4e4 !important;
}
.display-3 {
  color: #e4e4e4;
}
body {
  background-color: #16191f !important;
}
.bg-dark {
  background: #2a2e33 !important;
}
.sidebar .nav .nav-item.nav-category {
  //
}
.sidebar .nav .nav-item .nav-link {
  color: #e4e4e4;
}
.card {
  background: #16191f !important;
}
.sidebar .nav .nav-item.active > .nav-link {
  background: rgb(42 46 51);
}
.sidebar .nav .nav-item.active > .nav-link .menu-title {
  color: #0073bb;
  font-weight: 600;
}
.sidebar .nav .nav-item .menu-icon {
  background: transparent;
}
.sidebar .nav .nav-item .nav-link i {
  color: #7d7d7d;
  font-size: 25px;
}
.sidebar .nav .nav-item.active > .nav-link i {
  color: #208177;
}
.sidebar-brand-wrapper h3,
.profile-name h5 {
  color: #e4e4e4;
}
.table tbody tr td.text-primary.font-weight-bold {
  background: rgb(0 115 187 / 20%) !important;
  color: #0073bb !important;
  vertical-align: middle;
  font-weight: 600;
}
.table tbody tr td .badge.badge-outline-success {
  // color: #208177 !important;
  border: 1px solid #0073bb !important;
  font-size: 14px;
  color: #0073bb;
}
.table tbody tr td .btn.btn-primary.mx-1 {
  background: #0073bb;
  color: #e4e4e4 !important;
  border: 0;
  padding: 6px 10px;
}
.sidebar .nav .nav-item.nav-category .nav-link {
  color: #0073bb;
}
.add_admin_form h4 {
  color: #e4e4e4 !important;
}
.add_admin_form {
  max-width: 530px;
  margin: auto;
  box-shadow: rgb(137 124 124 / 16%) 0px 10px 36px 0px,
    rgb(48 48 48 / 6%) 0px 0px 0px 1px;
  padding: 30px;
}
.add_admin_form label {
  color: #e4e4e4;
  font-weight: 500;
}
.add_admin_form .form-control,
.form-control,
select.form-control,
.rmdp-container input {
  color: #e4e4e4 !important;
  background: #2a2e33 !important;
  // border: 0;
  border-radius: 10px;
  padding: 20px;
  height: auto;
  outline: none;
  border: 1px solid gray;
}
.rmdp-container {
  width: 75%;
}
.rmdp-container input {
  width: 100%;
  padding: 16px;
}
select.form-control {
  color: #e4e4e4 !important;
  background-image: url(../assets/images/select-arrow.png) !important;
  background-position: right 10px center !important;
  background-repeat: no-repeat !important;
}
.add_admin_form .btn.btn-success,
.btn.btn-sm.btn-primary,
.btn.btn-primary {
  background: #0073bb;
  padding: 15px;
  color: #e4e4e4;
  border: 0;
  border-radius: 10px;
}
.btn.btn-sm.btn-outline-info,
.btn.btn-outline-info {
  color: #0073bb;
  border: 1px solid #0073bb !important;
  font-size: 14px;
}

.btn:hover,
.btn.btn-sm.btn-outline-info:hover {
  background: #0073bb;
  color: #e4e4e4 !important;
}
.font-weight-bold.text-success {
  color: #0073bb !important;
  background: #0073bb2e;
  // font-size: 14px;
}
h3,
div {
  color: #e4e4e4;
}
#printablediv table {
  width: 100%;
}
.ant-table-wrapper,
.ant-table-content table {
  width: 100%;
}
.btn.btn-danger {
  background: #0073bb;
  color: #e4e4e4;
  border: 0;
  padding: 15px;
  border-radius: 10px;
}
#DataTables_Table_0_wrapper .btn.btn-danger {
  background: #dc3545;
}
// select{

// }
h3.text-white,
h4.text-light {
  color: #e4e4e4 !important;
  font-weight: 600 !important;
}
.input-group-prepend .input-group-text {
  padding: 21px;
  background-color: #2a2e33 !important;
  color: #e4e4e4 !important;
}
.setting_card .card {
  height: 100%;
}
.block_btn.btn-danger {
  background: #dc3545 !important;
  padding: 6px 10px;
}
.edit_user.btn-info {
  background: #208177;
  color: #e4e4e4 !important;
  border: 0;
  padding: 6px 10px;
  border-radius: 15px;
}
.edit_user.btn-info:hover {
  background: #208177;
}
.dataTables_filter {
  margin-bottom: 10px;
}
.dataTables_length label,
.dataTables_filter label {
  color: #000;
}
.card {
  margin-top: 0 !important;
}
.footer
  .container-fluid
  .d-sm-flex.justify-content-center.justify-content-sm-between.py-2.w-100 {
  justify-content: center !important;
}
.notification_send {
  max-width: 530px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 30px;
}
.notification_send label {
  color: #000;
  margin-top: 15px;
}
div#printablediv {
  max-width: 1000px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 30px;
  margin-bottom: 30px;
  background: #16191f;
}
.nav-item h6,
.col-sm-6 {
  color: #e4e4e4 !important;
}
.alluser .text-success {
  color: #208177 !important;
}
.other_settings .form-row {
  height: 100%;
}
.add_new_user {
  max-width: 530px;
  margin: auto;
  box-shadow: rgba(137, 124, 124, 0.16) 0px 10px 36px 0px,
    rgba(48, 48, 48, 0.06) 0px 0px 0px 1px;
  padding: 30px;
}
.add_new_user .btn.btn-success {
  background: #0073bb !important;
  color: #e4e4e4;
  padding: 20px;
  border: 0;
  border-radius: 20px;
}
.sidebar .nav .nav-item .menu-icon svg path {
  fill: #e4e4e4;
}
.sidebar .nav .nav-item.active .menu-icon svg path {
  fill: #0073bb;
}
.form-control:focus {
  border: 1px solid rgb(0 115 187 / 35%);
}
.sidebar .nav {
  gap: 15px;
}
label {
  color: #e4e4e4 !important;
  font-weight: 500 !important;
}
.list-group-item.bold {
  font-size: 18px;
  font-weight: 900;
}
ul.snip-ul li {
  color: #000;
}
.footer a {
  color: #0073bb;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
  color: #e4e4e4;
}
.sidebar
  .nav:not(.sub-menu)
  > .nav-item:hover:not(.nav-category):not(.account-dropdown)
  > .nav-link {
  background: #2a2e33;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover,
.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #0073bb;
}
.setting_card .col-md-4 .card,
.form-row .card {
  background: #2a2e33 !important;
}
:where(
    .css-dev-only-do-not-override-3mqfnx
  ).ant-divider-horizontal.ant-divider-with-text {
  color: #e4e4e4 !important;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  color: #e4e4e4;
  background: #2a2e33;
  border-bottom: 1px solid #2a2e33;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  color: #e4e4e4 !important;
  background: #2a2e33 !important;
}
h4 {
  color: #e4e4e4;
}
.bg-white {
  background: #16191f !important;
}
.Login-button {
  background: #0073bb;
  color: #e4e4e4 !important;
  padding: 10px 30px;
}
.list-group-item {
  background: #2a2e33;
  color: #e4e4e4;
  border-bottom: 1px solid #ffffff71;
}
