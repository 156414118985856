.my-atropos {
  width: 320px;
  height: 160px;
}
#dashFooter {
  background-color: unset !important;
}
.dashboaed_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box-desing {
  background-color: #fff;
  padding: 15px 10px;
  margin-bottom: 10px;
}
.box-desing h3 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
}
.box-desing span {
  font-weight: 400;
  font-size: 18px;
}
.box-desing-h2 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
