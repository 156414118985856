.img {
  width: 200px;
  border-radius: 5px;
  cursor: zoom-in;
  display: block;
}

.img-panel {
  padding: 5px;
  background-color: transparent;
  height: auto;
  width: auto;
  display: inline-block;
  transition: all 200ms ease;
  border-radius: 5px;
}
.img-panel:hover {
  background-color: #20a4f3;
}

.img-panel__selct {
  background-color: #20a4f3;
}

.display-box {
  padding: 1em;
  max-width: 1000px;
  min-width: 300px;
  margin: 1em auto;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, 210px);
  grid-autoflow: row;
  justify-content: center;
}

.img-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: all 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.img-wrapper__initial {
  background-color: rgba(0, 0, 0, 0.7);
}

.image__selected {
  max-width: 70vw;
  max-height: 80vh;
  display: block;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  position: relative;
  border: transparent solid 4px;
  border-radius: 5px;
}

.img-selected-initial {
  border: white solid 4px;
}

.img-close {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 1.5em;
  left: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  border-radius: 5px;
}
.img-close:hover {
  background-color: #ca3b4e;
}
.img-close:before {
  content: " ";
  height: 40px;
  width: 5px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  cursor: pointer;
}
.img-close:after {
  content: " ";
  height: 40px;
  width: 5px;
  transform: rotate(135deg);
  position: absolute;
  z-index: 100;
  background-color: white;
  cursor: pointer;
}

.dashboaed_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderReturn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
}
.loader-overlay {
  background-image: url("https://api.mjludo.com/public/icon/1684930333690-1-867297072.webp");
}
