.invoice {
  /* border: 2px solid black; */
  width: 100%;
}
td,
th {
  height: 35px;
}
table h1,
p {
  margin-bottom: 0;
  padding-bottom: 0;
}
address {
  margin-bottom: 0;
}
.table-1-head {
  padding: 0px 40px;
}
.font_sm {
  font-size: 11px;
  font-weight: 100;
}
.company_name {
  font-size: 25px;
  font-weight: 700;
}
.date {
  font-size: 20px;
  font-weight: 600;
}
.detail {
  font-size: 18px;
  font-weight: 500;
}
.detail2 {
  font-size: 17px;
  font-weight: 500;
}
.paytment {
  width: 80%;
  margin: auto;
}
.table_1 {
  padding: 0px 10px;
  position: relative;
  top: -25px;
}
.table_2_tr td {
  padding-bottom: 10px;
}
.width_50 {
  width: 50%;
}
.width_20 {
  width: 20%;
}
.width_25 {
  width: 25%;
}
.width_30 {
  width: 30%;
}
.width_5 {
  width: 5%;
}
.width_45 {
  width: 45%;
}
.width_55 {
  width: 55%;
}
.bottom_table {
  display: flex;
  width: 100%;
}
.hei_30 {
  max-height: 70px;
  height: 70px;
}
.same_height {
  width: 100%;
  height: 231px;
  max-height: 231px;
}
.pd_left {
  padding-left: 10px;
}

.w_100 {
  width: 100%;
}
.op {
  opacity: 0;
}
.top_table {
  display: flex;
  justify-content: space-between;
}
.text_center {
  text-align: center;
}
.onediv {
  border-left: 2px solid black;
  border-right: 2px solid black;
}
.la table,
th,
td,
tr {
  border-right: 0px !important;
  border-top: 0px;
}
.la2 table,
th,
td,
tr {
  border-top: 0px;
  color: #000;
}
.invoice-border table {
  width: 100%;
}
.invoice-border {
  padding: 10px 70px;
  border-bottom: 2px solid #e8e3e3;
}
.invoice-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.invoice-img img {
  height: 100px;
  width: 150px;
}
.invoice-h1 h1 {
  font-weight: 600;
  font-size: 20px;
}
.invoice-h2,
.invoice-h2 h1,
.invoice-h2 h3,
.invoice-h2 h2,
.invoice-h2 h4,
.invoice-h2 h5,
.invoice-h2 h6,
.invoice-h2 p,
.invoice-h2 span {
  color: #000;
}
.invoice-h2 {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  font-family: "Roboto";
}
.invoice-h3 {
  font-weight: 500;
  font-size: 16px;
  color: #5e6960;
  font-family: "Roboto";
}
.invoice-h4 {
  font-weight: 500;
  font-size: 14px !important;
  color: #5e6960;
  font-family: "Roboto";
}
.invoice-border.side {
  text-align: end;
}
.invoice-border.padding {
  padding-bottom: 100px;
}
.invoice-table tr th {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
}
.invoice-table.color tr th {
  color: #9c9a97;
}
.invoice-table tr td {
  font-size: 16px !important;
  font-weight: 500;
  color: #000;
  font-family: "Roboto" !important;
}
.invoice-table tr th:last-child {
  text-align: end;
}
.invoice-table tr td:last-child {
  text-align: end;
}
.invoice-message {
  font-size: 16px !important;
  color: #5e6960;
  margin-bottom: 30px !important;
}
.invoice-words {
  font-size: 16px !important;
}
.invoice-qr {
  text-align: center;
  padding: 40px 0px;
}
.invoice-qr h4 {
  font-size: 14px !important;
}
.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.signature-block {
  width: 48%;
}
.signature-block p {
  margin-bottom: 20px;
}
.company-stamp {
  background-color: yellow;
  display: inline-block;
  padding: 5px;
  font-weight: bold;
}
