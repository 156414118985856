.chat-container {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-image: url("https://w0.peakpx.com/wallpaper/818/148/HD-wallpaper-whatsapp-background-cool-dark-green-new-theme-whatsapp.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.chat-content {
  max-width: 450px;
  margin-top: 66px;
  margin-left: 19px;
}
.unseen_msg {
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: green;
  /* padding: 4px 4px 4px 4px; */
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  width: 20px;
  height: 20px;
}

.unseen_msg span {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* .chat-header {
  padding: 12px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ccc;
  background-color: #007bff;
  color: white;
} */

.row-reverse {
  text-align: right;
}

.chat-messages {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  /* display: flex; */
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.user-message {
  display: inline-block !important;
  /* background-color: #e2f2ff;*/
  background-color: #e2f2ff;
  margin-left: auto;
  max-width: 50% !important;
  width: auto !important;
  padding: 5px;
}
.msg_bg {
  display: inline;

  border-radius: 5px;
  word-break: break-all;
}
.admin-message {
  margin-right: auto;
  max-width: 50% !important;
  width: auto !important;
  background-color: #46ffe6;
  padding: 5px;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px !important;
}

.chat_input_modal {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px !important;
}

.chat-input input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.chat_input_modal:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.chat-input button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}
.modal_button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px !important;
}
.modal_button:hover {
  background-color: #0056b3;
}
.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  background-color: #007bff;
  color: white;
}

.chat_list {
  font-size: 17px;
  cursor: pointer;
}
.chat_list_cont {
  position: absolute;
  background-color: #ccc;
  width: 25%;
  height: 85%;
  overflow-x: scroll;
  overflow-x: hidden;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .chat_list_cont {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .chat_list_cont {
    width: 80%;
  }
}

.chat_list_cont .chat_single_div {
  padding: 10px;
  margin-bottom: 1px;
  border-bottom: 2px solid white;
  font-weight: bold;
}

.end_chat_btn {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}
.end_chat_btn button {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* SearchBox */
.SearchBox {
  /* position: sticky; */
  top: 0px;
  z-index: 99;
  border-radius: 0px !important;
}

.loaderReturn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
}
.loader-overlay {
  background-image: url("https://api.mjludo.com/public/icon/1684930333690-1-867297072.webp");
}
