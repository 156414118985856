/* Todo list */

.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;
  input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    background: transparent;
  }
  .btn {
    margin-left: 0.5rem;
    .rtl & {
      margin-left: auto;
      margin-right: 0.5rem;
    }
  }
}

.list-wrapper {
  height: 100%;
  max-height: 100%;
  ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0;
    li {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-start;
      font-size: 0.9375rem;
      padding: 0.8rem 0;
      border-bottom: 1px solid $border-color;
      &:last-child {
        border-bottom: none;
      }
      .form-check {
        @extend %ellipsor;
        max-width: 90%;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        .form-check-label {
          @extend%ellipsor;

          input {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }

  .remove {
    // @extend .ml-auto;
    @extend .text-secondary;
    // .rtl & {
    //   // @extend .ml-0;
    //   @extend .mr-auto;
    // }
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 20px;
    text-align: center;
  }

  .completed {
    text-decoration: line-through;
    text-decoration-color: theme-color(primary);
    .remove {
      @extend .text-primary;
    }
  }
}
